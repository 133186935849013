@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif;
}

h1 {
  font-family: 'Lato', sans-serif;
}

h2 {
  font-family: 'Lato', sans-serif;
}

h3 {
  font-family: 'Lato', sans-serif;
}

h4 {
  font-family: 'Lato', sans-serif;
}

.strata-boss-bg-green {
  background-color: #32aab8;
}
.strata-boss-bg-white {
  background-color: #F7F6F1;
}
.strata-boss-text-white {
  color: #F7F6F1;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.login-button-color {
  background-color: #167f8b;
  border-color: #ffffff;
}

.login-button-color:hover {
  background-color: #32aab8;
  border-color: #167f8b;
}

.login-button-color:active {
  background-color: #32aab8;
  border-color: #167f8b;
}

.login-button-color:focus {
  background-color: #32aab8;
  /* or any other color you want */
  outline: none;
  /* removes the default outline */
}

.dashboard-tile {
  background-color: #F7F6F1;
  height: 200px;
  border-radius: 10px;
  border: 2px solid #990234;
}

.dashboard-heading {
  text-shadow: -1px 0 #025d68, 0 1px #025d68, 1px 0 #025d68, 0 -1px #025d68;
  color: #F7F6F1;
  text-align: left;
  margin-bottom: 25px;

}

.top-dashboard-container {
  background-color: #F7F6F1;
  max-height: 100px;
  border-radius: 10px;
  border: 2px solid #990234;
  height: 50%;
  width: 80%;
  /* responsive width */
  max-width: 1800px;
  /* maximum width */
  margin: 0 auto;
  /* center the container */
}

@media (max-width: 1800px) {
  .top-dashboard-container {
    width: 100%;
    /* full width on small screens */
  }
}

@media (max-height: 100px) {
  .top-dashboard-container {
    height: 100%;
    /* full height on small screens to cater for text wrapping */
  }
}

.dashboard-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background-color: #ffcc00;
  color: #990234;
  border-radius: 5px;
}

.dashboard-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background-color: #016030;
  color: #ffa703;
  border-radius: 5px;
}

.app-footer {
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.footer-image {
  position: relative;
  top: -6px;
}

.edit-profile-button {
  display: inline-block;
  width: 150px;
  height: 35px;
  background-color: #32aab8;
  color: white;
  padding: 5px 20px;
  text-decoration: none;
  border-radius: 20px;
}

.edit-profile-button:hover {
  background-color: #6acad9;
  color: #025d68;
}

.profile-picture {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.profile-image-container {
  position: relative;
  display: inline-block;
}

.edit-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #32aab8;
  border-radius: 50%;
  padding: 5px;
  color: #272727;
}

.horizontal-line {
  border-top: 1px solid #bebebe;
  padding-top: 10px;
}

.settings-row {
  align-items: center;
}

.icon-circle {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.settings-icon {
  color: #990234;
}

.arrow-icon {
  color: #b9b9b9;
}

.settings-row {
  align-items: center;
  padding: 10px;
  /* Adjust this value */
}

.register-button {
  display: inline-block;
  width: 250px;
  height: 40px;
  background-color: #32aab8;
  color: white;
  padding: 5px 20px;
  text-decoration: none;
  border-radius: 25px;
}

.register-button:hover {
  background-color: #6acad9;
  color: #025d68;
}

.page-title {
  padding-top: 20px;
  /* Adjust this value as needed */
}

.property-details-tile-heading {
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
}

.property-details-text {
  line-height: 0.9;
  padding-left: 25px;
  /* Adjust this value as needed */
}

.financials-text {
  line-height: 1.8;
  padding-left: 25px;
  /* Adjust this value as needed */
}

.strata-admin-text {
  line-height: 1.2;
  padding-left: 25px;
  /* Adjust this value as needed */
}

.tile-table-row {
  padding-left: 25px;
  /* Adjust this value as needed */
  padding-right: 25px;
  text-align: center;
  /* Adjust this value as needed */
}

.gap-1 {
  gap: 5px;
}

.gap-2 {
  gap: 10px;
}

.gap-3 {
  gap: 15px;
}

.gap-4 {
  gap: 20px;
}

.gap-5 {
  gap: 25px;
}