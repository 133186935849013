.oc-table-row {
    padding: 10px;

}

.center-text {
    text-align: center;
}

.table-header {
    background-color: #6acad9;
    padding: 10px 20px 10px 20px;
}

.wideTable {
    width: 100% !important;
}

.center-heading {
    text-align: center !important;
}

.center-table {
    margin-left: auto;
    margin-right: auto;
    width: 90%; /* Adjust this value as needed */
}

.form-container {
    display: flex;
    flex-direction: column;
    background: rgba(247, 246, 241, 0.75) !important; /* Remove the white background */
    border: none !important;
}

.modal-container {
    width: 60%; /* Adjust as needed */
    height: 70%; /* Adjust as needed */
    overflow: auto; /* Add scroll if content is larger than modal */
    margin: auto;
    padding: 20px;
    background: rgba(247, 246, 241, 0.75);
    display: flex;
    justify-content: center;
    align-items: center; /* Center the modal */
}

.form-container input, .form-container select {
    margin-bottom: 20px; /* Adjust as needed */
}

.flex-row {
    display: flex;
    align-items: center;
}

.label-input {
    margin-right: 10px; /* Adjust as needed */
}

.select-width {
    width: 250px; /* Adjust as needed */
}

.input-height {
    height: calc(1em + 20px);/* Adjust as needed */
    color: #000;
    padding-left: 10px;
}

.submit-button {
    display: inline-block;
    width: 100%;
    height: 35px;
    background-color: #32aab8;
    color: white;
    padding: 5px 20px;
    text-decoration: none;
    border-radius: 20px;
    border: none;
  }
  .submit-button:hover {
    background-color: #6acad9;
    color: #025d68;
  }

.owner-occupied {
    padding-top: 10px !important; /* Adjust as needed */
}

.checkbox-input {
    transform: scale(1.2); /* Adjust as needed */
}

.checkbox-label {
    padding-right: 10px !important; /* Adjust as needed */
}

.radio-input {
    transform: scale(1.5); /* Adjust as needed */
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content h6 {
    padding-bottom: 20px; /* Add padding after the heading */
}
