.generate-invoices-button {
  display: inline-block;
  font-size: 16px !important;
  width: 250px;
  height: 40px;
  background-color: #32aab8;
  color: white;
  padding: 5px 20px;
  text-decoration: none;
  border-radius: 20px;
}

.generate-invoices-button:hover {
  background-color: #6acad9;
  color: #025d68;
}