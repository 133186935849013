.ps-table-row {
  padding: 10px 10px 10px 20px !important;
}

.wider-input {
  width: 150%;
}

.center-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.table-row:nth-child(even) {
  background-color: #e8e8e8;
}

.table-row:nth-child(odd) {
  background-color: #ffffff;
}